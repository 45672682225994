<template>
    <div :class="[(!erreur && !succes) ? 'invisible' : '', erreur ? 'erreur' : 'succes']" >{{ page_stripe().messages[erreur + succes]}}</div>
    <!--<label for="card-element">Paiement</label>
    <div id="card-element"></div>-->

    <div id="card-number"></div>
    <div class="under">
        <div id="card-expiry"></div>
        <div id="card-cvc"></div>
    </div>
    <div v-if="action!='update'" class="paiements_acceptes">{{ page_stripe().content.payment_methods }}</div>
    <div v-if="action!='update'" class="favicons_paiements_acceptes">
        <i class="fab">&#xf1f0;</i>
        <i class="fab">&#xf1f1;</i>
    </div>
    <div @click="clientSecret" v-if="afficherPaiement" class="payer">
        <div class="boutton_offre">
        <p class="texte_boutton_offre">{{libelle}}</p>
        </div>
    </div>
    <div v-else class="payer">
        <div class="boutton_offre">
        <p class="texte_boutton_offre">. . .</p>
        </div>
    </div>
    <div v-if="action!='update'" class="paiement_securise">{{ page_stripe().content.asterisque }}</div>
</template>

<script>
import axios from 'axios';
import json_config from "@/json/config.json";
import page_str from "@/json/page_stripe.json"

var stripe = Stripe('pk_test_51IkWK6HdFSoTgM7SA4vCY276W4ADAq0VLX4kzKDi17DgR257XLy3XtoAvTqYKRHTikPLENkhyhlbnYg1Kb74PHaD00TopOIXvU');

var elements = stripe.elements(
    {
        fonts: [
            {
                cssSrc: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap'
            }
        ]
    }
);
//var card = elements.create('card');

//var cardNumber = elements.create('cardNumber')
//var cardExpiry = elements.create('cardExpiry')
//var cardCvc = elements.create('cardCvc')


//Pour custom le style voir
//https://stripe.com/docs/js/appendix/style
var style = {
    base: {
        iconColor: '#000000',
        color: '#000000',

        '::placeholder': {
        color: '#D2D1D1',
        },
    },
    invalid: {
        iconColor: '#F2505D',
        color: '#F2505D',
    },
    complete:{
        iconColor: '#04D98B',
        color: '#04D98B',
    }
};

var cardNumber = elements.create('cardNumber', {
  style: style
});
//cardNumber.mount('#card-number');

var cardExpiry = elements.create('cardExpiry', {
  style: style
});
//cardExpiry.mount('#card-expiry');

var cardCvc = elements.create('cardCvc', {
  style: style
});
//cardCvc.mount('#card-cvc');



export default {
  name: 'Stripe',
    props:['libelle', "action", "abonnementId"],
    data(){
        return{
            page_str: page_str,
            afficherPaiement:true,
            erreur:"",
            succes:""
        }
    },
    methods:{
        page_stripe(){
            return this.page_str[this.$store.state.codeLangue]
        },
        clientSecret(){
            this.afficherPaiement=false
            this.erreur=""
            this.succes=""

            var mesDonnees = new FormData();
            //Si c'est un update on envoie en plus l'id de l'intent à modifier
            mesDonnees.set("mail", this.$store.state.currentUser.mail);
            mesDonnees.set("step", "step1");
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/getClientSecret.php',
                data: mesDonnees
            })
            .then((result) => {
                if(this.action=="new"){
                    //result.data est le client secret
                    this.payer(result.data)
                }
                else if (this.action=="update"){
                    stripe.createToken(cardNumber).then((result)=>{
                        if(result.error){
                            this.erreur = result.error.code
                            this.afficherPaiement=true
                        }
                        else{
                            //Recup du token ok
                            console.log(result)
                            this.updateCard(this.abonnementId, result.token.id)
                        }
                    })
                }
            })
        },

        payer(client_secret){
            stripe.confirmCardPayment(client_secret, {
                payment_method: {
                    card: cardNumber
                }
            }).then((result)=>{
                if(result.error){
                    this.afficherPaiement=true
                    if(result.error.code=="payment_intent_unexpected_state"){
                        var obj = { "cocart": "nouveauPanier", "intentType": "new", "amount": this.$store.getters.getPrixPanierCents, "router": this.$router}
                        this.$store.dispatch("sendToCocart", obj)
                        this.erreur = "processing_error"
                    }
                    else{
                        this.erreur = result.error.code
                    }
                }
                else{
                    var pm = result.paymentIntent.payment_method
                    var pi = result.paymentIntent.id
                    stripe.createToken(cardNumber).then((result)=>{

                        //On vide le panier
                        this.$store.dispatch("changerPanier", [])
                        //On redirige vers la page de succes du paiement
                        this.$router.push({path:"/succes", redirectedFrom:"panier"})
                        //Ici on envoie la commande dans woocommerce
                        this.sendToWooCommerce(pi, pm, result.token.id)
                    })
                }
            })
        },

        async updateCard(abonnement, token){
            var mesDonnees = new FormData();
            //Si c'est un update on envoie en plus l'id de l'intent à modifier
            mesDonnees.set("mail", this.$store.state.currentUser.mail);
            mesDonnees.set("abonnementId", abonnement);
            console.log(abonnement);
            mesDonnees.set("token", token);
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/updateCard.php',
                data: mesDonnees
            }).then((result)=>{
                console.log(result)
                this.succes="card_changed_successfully"
                this.afficherPaiement=true
            })
        },

        async sendToWooCommerce(pi, pm, token){
            var mesDonnees = new FormData();
            //Si c'est un update on envoie en plus l'id de l'intent à modifier
            mesDonnees.set("mail", this.$store.state.currentUser.mail);
            mesDonnees.set("pi", pi);
            mesDonnees.set("pm", pm);
            mesDonnees.set("token", token);
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/commande.php',
                data: mesDonnees
            }).then((result)=>{
                console.log(JSON.stringify(result.data))
                this.erasePanierCocart()
            })
        },

        async erasePanierCocart(){
            var mesDonnees = new FormData();
            //Si c'est un update on envoie en plus l'id de l'intent à modifier
            mesDonnees.set("mail", this.$store.state.currentUser.mail);
            mesDonnees.set("step", "step2");
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/getClientSecret.php',
                data: mesDonnees
            }).then((result)=>{
                console.log(result)
            })
        }
    },
    mounted(){
        //card.mount('#card-element')
        cardNumber.mount('#card-number')
        cardExpiry.mount('#card-expiry')
        cardCvc.mount('#card-cvc')
        this.afficherPaiement=true
    }
}
</script>

<style scoped>
.invisible{
    visibility: hidden!important;
}

.erreur{
    text-align: center;
    color: #F2505D;
    margin-top: 8px;
    margin-bottom: 8px;
    min-height: 34px;

    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.succes{
    text-align: center;
    color: #04D98B;
    margin-top: 8px;
    margin-bottom: 8px;
    min-height: 34px;

    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

#card-number{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 8px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 16px;
}

.under{
    display:flex;
}

#card-expiry{
    display: block;
    width: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 8px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

#card-cvc{
    display: block;
    width: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 8px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.paiements_acceptes{
    margin-top: 24px;
    text-align: left;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.payer{
    display: flex;
    justify-content: space-around;
    margin-top: 39px;
}

.favicons_paiements_acceptes{
    text-align: left;
}

i{
    margin-right: 6px;
    margin-top: 8px;
}

.paiement_securise{
    text-align: left;
    margin-top: 38px;
    
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
}

.boutton_offre{
    position:relative;
    background:#42b983!important
}
</style>