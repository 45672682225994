<template>
    <div class="main">
        <div @click="$router.push('/panier'), couponSended=false, displayCoupon=false" v-if="verifierPanier()=='commande'" class="left">
            <i :class="{'will-fadeIn' : $store.state.cliquable}" class="fas pointer backToDesk">&#xf30a;</i>
        </div>
        <div class="middle">
            <div class="middle_1" :class="{'fadeFromRight': $store.state.cliquable && verifierPanier()=='panier', 'will-fadeIn' : $store.state.cliquable}" v-if="verifierPanier()=='panier'">{{ page_panier().panier.title }}</div>
            <div class="middle_1" :class="{'fadeFromRight': $store.state.cliquable && verifierPanier()=='panier', 'will-fadeIn' : $store.state.cliquable}" v-if="verifierPanier()=='commande'">{{ page_panier().commande.title }}</div>
            <div class="middle_2" :class="{'fadeFromRight': $store.state.cliquable && verifierPanier()=='panier', 'will-fadeIn' : $store.state.cliquable}"></div>
            <div v-if="this.$store.state.panier.length>0">   
                <div class="produit" v-for="(prod, indexProd) in this.$store.state.panier" :key="indexProd">
                    <div class="middle_3_1" :class="{'fadeFromRight': indexProd%2==1 && $store.state.cliquable && verifierPanier()=='panier', 'will-fadeIn' : $store.state.cliquable}">
                        <span class="middle_3_1_1">{{ prod.quantity }}x</span>
                        <span>{{ prod.name }}</span>
                    </div>
                    <!--<div class="middle_3_2">{{prod.meta_data.display_key }} : {{ prod.meta_data.value }}</div>-->
                    <div class="middle_3_2" :class="{'fadeFromRight': indexProd%2==1 && $store.state.cliquable && verifierPanier()=='panier', 'will-fadeIn' : $store.state.cliquable}" v-for="(meta, indexMeta) in prod.meta_data" :key="indexMeta">{{meta.display_key }} : {{ meta.value }}</div>
                    <div class="middle_3_3" :class="{'fadeFromRight': indexProd%2==1 && $store.state.cliquable && verifierPanier()=='panier', 'will-fadeIn' : $store.state.cliquable}">
                        <div class="table_cell table_middle middle_3_3_1"><img class="tableDisplay" src="../assets/product_icon_panier.png"></div>
                        <!--Si aucun coupon n'est appliqué ou qu'on est encore au panier-->
                        <div class="table_cell table_middle middle_3_3_2">
                            <div class="middle_3_3_2_general">
                                <div>
                                    <div v-if="!this.$store.state.panier[0].coupons|| verifierPanier()=='panier'">{{ Number(prod.price).toFixed(2) }}</div>
                                    <div v-else>{{ Number(prod.priceAfterDiscount).toFixed(2) }}</div>
                                </div>
                                <div>
                                    <div class="eur_sign">€</div>
                                    <div v-if="produits.length>0" class="interval_abo">
                                        <div v-if="produits[prod.id].intervalP2">{{ produits[prod.id].intervalP1 + global_translate().interval[produits[prod.id].intervalP2] }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table_cell table_middle middle_3_2">{{ page_panier().panier.ht }}</div>
                    </div>
                    <div v-if="verifierPanier()=='panier'" class="middle_3_4 pointer" :class="{'fadeFromRight': indexProd%2==1 && $store.state.cliquable && verifierPanier()=='panier', 'will-fadeIn' : $store.state.cliquable}" @click="retirer(indexProd)">{{ page_panier().panier.delete }}</div>
                </div>
                <div v-if="verifierPanier()=='commande'" @click='displayCoupon = !displayCoupon' :class="{'will-fadeIn' : $store.state.cliquable}" class="gotCoupon">{{ page_panier().commande.coupon }}</div>
                <div v-if="displayCoupon || couponSended">
                    <div v-if="verifierPanier()=='commande'">
                        <div class="ensemble_coupon">
                            <div class="coupon_input_container">
                                <input type="text" v-model='coupon' :placeholder="page_panier().commande.coupon_placeholder">
                            </div>
                            <div class="boutton_coupon" @click="getInfosCoupon">{{ page_panier().commande.appliquer }}</div>
                        </div>
                    </div>
                    <div class="couponMessage" :class="[couponSended==false ? 'invisible' : '', couponBool ? 'couponOk' : 'couponError']">{{ page_panier().coupon[couponMessage] }}</div>
                </div>
                <div v-if="couponSended">
                    <div class="couponListe" v-for="(coupon, couponIndex) in this.$store.state.panier[0].coupons" :key="couponIndex">
                        <div>{{coupon.coupon}} : {{coupon.saving_html}}</div>
                        <div class="retirerCoupon" @click="deleteCoupon(coupon.coupon)">{{ page_panier().commande.retirer }}</div>
                    </div>
                </div>
                <div class="middle_3_5" :class="{'will-fadeIn' : $store.state.cliquable}"></div>
                <div class="flex space_between middle_3_6">
                    <div :class="{'will-fadeIn' : $store.state.cliquable}">{{ page_panier().panier.total }}</div>
                    <div class="flex space_between middle_3_6_2">
                        <div>
                            <br v-if="this.$store.state.panier[0].discount && verifierPanier()=='commande' && couponSended" />
                            <div :class="{'will-fadeIn' : $store.state.cliquable}">{{ page_panier().panier.ht }}</div>
                            <!--<div v-if="this.$store.state.panier[0].discount && verifierPanier()=='commande' && couponSended">Réduction</div>-->
                            <div :class="{'will-fadeIn' : $store.state.cliquable}" v-if="verifierPanier()=='commande'">{{ page_panier().panier.tva }} ({{ Math.round(100*(this.$store.getters.getTTCPanier-this.$store.getters.getPrixPanier)/this.$store.getters.getPrixPanier) }}%)</div>
                            <div :class="{'will-fadeIn' : $store.state.cliquable}" v-if="verifierPanier()=='commande'">{{ page_panier().panier.ttc }}</div>
                        </div>
                        <div class="middle_3_6_2_2">
                            <div :class="{'will-fadeIn' : $store.state.cliquable}" v-if="this.$store.state.panier[0].discount && verifierPanier()=='commande' && couponSended" class="oldPrice">{{ Number(this.$store.getters.getPrixPanierNoDiscount).toFixed(2) }}€</div>
                            <div :class="{'will-fadeIn' : $store.state.cliquable}" v-if="verifierPanier()=='commande' && couponSended">{{ Number(this.$store.getters.getPrixPanier).toFixed(2) }}€</div>
                            <div :class="{'will-fadeIn' : $store.state.cliquable}" v-else>{{  Number(this.$store.getters.getPrixPanierNoDiscount).toFixed(2) }}€</div>
                            <!--<div v-if="this.$store.state.panier[0].discount && verifierPanier()=='commande' && couponSended">- {{ this.$store.state.panier[0].discount/100 }}€</div>-->
                            <div :class="{'will-fadeIn' : $store.state.cliquable}" v-if="verifierPanier()=='commande'">{{ Number(this.$store.getters.getTTCPanier - this.$store.getters.getPrixPanier).toFixed(2) }}€</div>
                            <div :class="{'will-fadeIn' : $store.state.cliquable}" v-if="verifierPanier()=='commande'">{{  Number(this.$store.getters.getTTCPanier).toFixed(2) }}€</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no_product" :class="{'will-fadeIn' : $store.state.cliquable}" v-else>
                {{ page_panier().panier.empty }}
            </div>
            <div class="boutton_offre_container">
                <router-link :class="{'will-fadeIn' : $store.state.cliquable}" data-no-turbolink='false' @click="commander(false)" v-if="verifierPanier()=='panier' && this.$store.state.panier.length>0" to="/panier/commande" class="boutton_offre">
                    <p class="texte_boutton_offre">{{ page_panier().panier.bouton }}</p>
                </router-link>
            </div>
        </div>
        <div :class="[$store.state.cliquable ? ['will-fadeIn','fadeFromRight'] : '']" v-if="verifierPanier()=='commande'" class="right">
            <div class="right_1">
                <div class="right_1_1">{{ page_panier().commande.card_title }}</div>
                <Stripe v-if="page_panier().commande.bouton" :libelle="page_panier().commande.bouton + ' ' + this.$store.getters.getTTCPanier + '€'" :action="'new'"/>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Stripe from '@/components/Stripe.vue'
import json_config from "@/json/config.json";
import page_pan from "@/json/page_panier.json";
import glb_tr from "@/json/global_translate.json";
import fadeIn from "@/js/fadeIn.js";

export default {
    name: 'Panier',
    emits:[""],
    components: {
        Stripe,
    },
    data(){
        return{
            glb_tr: glb_tr,
            page_pan: page_pan,
            envoyerPanier: true,
            coupon: "",
            couponSended: false,
            couponMessage:"",
            couponBool: false,
            displayCoupon:false,
            produits:""
        }
    },
    methods:{
        global_translate(){
            return this.glb_tr[this.$store.state.codeLangue]
        },
        page_panier(){
            return this.page_pan[this.$store.state.codeLangue]
        },
        totalProduits(){
            return this.$store.state.panier.length
        },

        retirer(index){
            var panier=this.$store.state.panier
            var nbProd = panier.length
            var mise_en_conformite = this.getProductBySlug("mise-en-conformite")
            var multisite = this.getProductBySlug("multisite")

            console.log(this.getProductById(this.$store.state.panier[index].id))
            console.log(this.getProductBySlug("mise-en-conformite"))
            //Si il supprime un abo principal
            if(this.getProductById(this.$store.state.panier[index].id).id==mise_en_conformite.id){
                console.log("la on delete le 79.99")
                for(var i=0; i<nbProd; i++){
                    if(this.getProductById(panier[i].id).id==multisite.id){
                        console.log("la on est censé update le price")
                        panier[i].id = mise_en_conformite.id
                        panier[i].name = mise_en_conformite.name
                        panier[i].price = mise_en_conformite.price
                        //panier.push({id:this.produits_base[indiceOffre]['id'], name: this.produits_base[indiceOffre].categories[0]["name"], price: parseFloat(this.produits_base[indiceOffre]['price']),quantity: 1, meta_data:{_key: "Site concerné", value: element, display_key: "Site concerné", display_value: element}})
                        break;
                    }
                }
                this.$store.dispatch("changerPanier", panier)
            }

            this.$store.dispatch("retirerArticle", index)
            console.log(this.$store.state.panier)
            console.log(this.$store.state.vieuxPanier)

            //On update coté cocart
            var obj = { "cocart": "nouveauPanier", "intentType": "none", "amount": this.$store.getters.getPrixPanierCents, "router": this.$router, "updatVieuxPanier":true}
            this.$store.dispatch("sendToCocart", obj)
        },

        verifUser(){
            if(this.$store.state.currentUser.mail){
                this.$router.push("/panier/commande")
                //this.verifierIntent()
            }
            else{
                this.$router.push("/connexion/panier")
            }
        },

        verifierPanier(){
            if(this.$route.params.commande && this.$store.state.currentUser.mail){
                return "commande"
            }
            else{
                return "panier"
            }
        },

        verifierIntent(){
            //On déclare les variables
            var amount = this.$store.getters.getPrixPanierCents
            console.log(amount)
            var typeIntent=""
            var commande = ""
            //Si il y a un intent id
            if(this.$store.state.intent.id){
                //Si le montant n'a pas changé
                if(amount==this.$store.state.intent.amount){
                    console.log("c'est ok")
                }
                //Sinon on update l'intent
                else{
                    var typeIntent="update"
                }
            }
            //Sinon on cree un nouveau intent
            else{
                var typeIntent="new"
            }
            //Si il y a un intent a creer ou a updater
            if(typeIntent){
                var mesDonnees = new FormData();
                //Si c'est un update on envoie en plus l'id de l'intent à modifier
                if(typeIntent=="update"){
                    mesDonnees.set("id", this.$store.state.intent.id);
                }
                mesDonnees.set("intent", typeIntent);
                mesDonnees.set("amount", amount);
                mesDonnees.set("currency", "eur");
                mesDonnees.set("payment_method_types", this.$store.state.payment_method_types);
                axios({
                    method: 'post',
                    url: json_config.phpFiles_url + '/intent.php',
                    data: mesDonnees
                })
                .then((result) => result.data)
                .then((result) => {
                    let newIntent = {id: result.id, amount: result.amount}
                    this.$store.dispatch("defineIntent", newIntent)
                    console.log(result)
                    if(result.id){
                        if(typeIntent=="new"){
                            commande="newCommande"
                        }
                        else{
                            commande="updateCommande"
                        }
                    }
                })

                //Si il y a eu succes de création/modification de l'intent
                .then((result) => {
                    if(commande){
                        var mesDonnees = new FormData();
                        mesDonnees.set("intent", commande);
                        mesDonnees.set("mailCustomer", this.$store.state.currentUser.mail);
                        mesDonnees.set("panier", JSON.stringify(this.$store.state.panier));
                        axios({
                            method: 'post',
                            url: json_config.phpFiles_url + '/intent.php',
                            data: mesDonnees
                        })
                        .then((result) => {
                            console.log(result)
                        })
                    }
                    else{
                        console.log("pas de commande")
                    }
                })
            }
        },

        verifierCoupon(){
            this.$store.state.cliquable=false
            this.couponSended=true
            var mesDonnees = new FormData();
            mesDonnees.set("mail", this.$store.state.currentUser.mail);
            mesDonnees.set("request", "post");
            mesDonnees.set("coupon", this.coupon);
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/coupon.php',
                data: mesDonnees
            })
            .then((result) => {
                if(result.data.response){
                    this.couponBool = true
                    this.couponMessage = 'applied'
                    //Un premiere fois juste avec l'objectif de récuprer le prix
                    var obj = { "cocart": "chargerPanier", "intentType": "update", "amount": this.$store.getters.getPrixPanierCents, "router": this.$router, "route": this.$route, "restart_for_coupon": true}
                    this.$store.dispatch("sendToCocart", obj)

                    //Une deuxième fois avec l'objectif de mettre à jour le prix coté stripe
                    /*var obj = { "cocart": "chargerPanier", "intentType": "update", "amount": this.$store.getters.getPrixPanierCents, "router": this.$router, "route": this.$route}
                    this.$store.dispatch("sendToCocart", obj)*/
                }
                else{
                    this.$store.state.cliquable=true
                    this.couponBool = false
                    console.log(result.data)
                    var wrongCode = this.coupon

                    if(result.data.code=="cocart_pro_coupon_failed_to_apply"){
                        this.couponMessage = "cant_apply"
                    }
                    else{
                        this.couponMessage = "invalid"
                    }
                }
            })
        },

        getInfosCoupon(){
            this.$store.state.cliquable=false
            this.couponSended=true
            var mesDonnees = new FormData();
            mesDonnees.set("coupon", this.coupon);

            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/getInfosCoupon.php',
                data: mesDonnees
            })
            .then((result) => {
                if(result.data.hasOwnProperty("discount_type")){
                    var type_discount = result.data.discount_type
                    console.log(result.data)
                    console.log(type_discount)

                    //Si le code réduction est 'fixed_cart' alors on fait un truc particulier
                    if(type_discount=="fixed_cart"){
                        this.couponMessage = "cant_apply"
                        this.$store.state.cliquable=true
                    }
                    //Sinon on envoie la verif du coupon
                    else{
                        this.verifierCoupon()
                    }
                }
                else{
                    this.couponMessage = "invalid"
                }
            })
        },

        deleteCoupon(coupon){
            this.$store.state.cliquable=false
            var mesDonnees = new FormData();
            mesDonnees.set("mail", this.$store.state.currentUser.mail);
            mesDonnees.set("request", "delete");
            mesDonnees.set("coupon", coupon);
            axios({
                method: 'post',
                url: json_config.phpFiles_url + '/coupon.php',
                data: mesDonnees
            })
            .then((result) => {
                console.log(result.data)
                if(result.data.response){
                    this.couponBool = true
                    this.couponMessage = "deleted"

                    //Un premiere fois juste avec l'objectif de récuprer le prix
                    var obj = { "cocart": "chargerPanier", "intentType": "update", "amount": this.$store.getters.getPrixPanierCents, "router": this.$router, "route": this.$route, "restart_for_coupon": true}
                    this.$store.dispatch("sendToCocart", obj)
                }
                else{
                    this.$store.state.cliquable=false
                    this.couponBool = false
                    this.couponMessage = "not_deleted"
                }
            })
        },

        getProductById(idP){
            for(var i=0; i<this.produits_base.length; i++){
                if(this.produits_base[i].id==idP){
                    break;
                }
            }
            return this.produits_base[i]
        },

        getProductBySlug(slugP){
        for(var i=0; i<this.produits_base.length; i++){
            if(this.produits_base[i].slug==slugP){
            break;
            }
        }
        return this.produits_base[i]
        },

        commander(forceUpadateBool){
            //Si le user est connecté
            if(this.$store.state.currentUser.mail){

                console.log(this.abonnements)
                var deletePrincipalAboInBasket = false
                //Si il en a actif alors on lui met tout en secondaire
                for(var i=0; i<this.abonnements.length; i++){
                  if(this.abonnements[i].status=="active") {
                    for(var j=0; j<this.abonnements[i].line_items.length; j++){
                        if(this.abonnements[i].line_items[j].product_id==this.getProductBySlug("mise-en-conformite").id){
                            deletePrincipalAboInBasket = true
                        }
                    }
                  }
                }

                var panier=this.$store.state.panier
                var nbProd = panier.length
                var mise_en_conformite = this.getProductBySlug("mise-en-conformite")
                var multisite = this.getProductBySlug("multisite")

                if(deletePrincipalAboInBasket){
                    for(var i=0; i<nbProd; i++){
                        if(this.getProductById(panier[i].id).id==mise_en_conformite.id){
                            panier[i].id = multisite.id
                            panier[i].name = multisite.name
                            panier[i].price = multisite.price
                            //panier.push({id:this.produits_base[indiceOffre]['id'], name: this.produits_base[indiceOffre].categories[0]["name"], price: parseFloat(this.produits_base[indiceOffre]['price']),quantity: 1, meta_data:{_key: "Site concerné", value: element, display_key: "Site concerné", display_value: element}})
                            break;
                        }
                    }
                }
                else{
                    console.log("s1")
                    var changeOne=true

                    for(var i=0; i<nbProd; i++){
                        if(this.getProductById(panier[i].id).id==mise_en_conformite.id){
                            changeOne = false
                            //panier.push({id:this.produits_base[indiceOffre]['id'], name: this.produits_base[indiceOffre].categories[0]["name"], price: parseFloat(this.produits_base[indiceOffre]['price']),quantity: 1, meta_data:{_key: "Site concerné", value: element, display_key: "Site concerné", display_value: element}})
                            break;
                        }
                    }

                    if(changeOne){
                        console.log("s2")
                        for(var i=0; i<nbProd; i++){
                            if(this.getProductById(panier[i].id).id==multisite.id){
                                panier[i].id = mise_en_conformite.id
                                panier[i].name = mise_en_conformite.name
                                panier[i].price = mise_en_conformite.price
                                //panier.push({id:this.produits_base[indiceOffre]['id'], name: this.produits_base[indiceOffre].categories[0]["name"], price: parseFloat(this.produits_base[indiceOffre]['price']),quantity: 1, meta_data:{_key: "Site concerné", value: element, display_key: "Site concerné", display_value: element}})
                                break;
                            }
                        }
                    }
                }

                this.$store.dispatch("changerPanier", panier)

                //Sinon on laisse comme c'est
                //Si le panier a bougé ou que la tva a changée
                if((JSON.stringify((this.$store.state.vieuxPanier)!=JSON.stringify(this.$store.state.panier) || this.$store.state.currentUser.tvaUpdated)) || forceUpadateBool){
                    //Si la tva a changée, j'indique que la modif a été prise en compte
                    if(this.$store.state.currentUser.tvaUpdated){
                        this.$store.state.currentUser.tvaUpdated=false
                        window.localStorage.currentUser=JSON.stringify(this.$store.state.currentUser)
                    }
                    this.$store.state.cliquable=false
                    var obj = { "cocart": "nouveauPanier", "intentType": "update", "amount": this.$store.getters.getPrixPanierCents, "router": this.$router, "updatVieuxPanier":true}
                    this.$store.dispatch("sendToCocart", obj)
                }
            }
            else{
                this.$store.state.erreurConnexion = "log_before_commande"
                this.$router.push("/connexion/panier")
            }
        }
    },

    watch: {
        '$store.state.cliquable': function() {
            if(this.$store.state.cliquable){
                setTimeout(()=>{
                    fadeIn.addFadeInFalse()
                }, 100);
                console.log("watched store")
            }
        }
    },

    mounted(){
        //Si on recharge la page commande je recharge le panier
        if((this.$route.fullPath=="/panier/commande" || this.$store.state.currentUser.mail) && this.$store.state.panier.length>0){
            this.$store.state.cliquable=false
        }
        else{
            this.$store.state.cliquable=true
        }
        if(this.$store.state.panier.length>0){
            //On recup les produits
            axios.get(json_config.phpFiles_url + "/produits.php")
            .then((result) => result.data)
            .then((result) => {
                console.log(result)
                this.produits_base = result
                var prods = []
                result.forEach(
                    element => {
                        var indice = element.id.toString()
                        //Si c'est une subscription on va enregistrer l'interval de paiement
                        if(element.type=="subscription"){
                            var intervalStringObj = element.meta_data.find(o => o.key === '_subscription_period');
                            var intervalIntObj = element.meta_data.find(o => o.key === '_subscription_period_interval');

                            var intervalInt = intervalIntObj.value

                            if(intervalInt=="1"){
                                var intervalP1 = "/"
                            }
                            else{
                                var intervalP1 = intervalInt + "/"
                            }

                            prods[indice] = {"intervalP1": intervalP1, "intervalP2": intervalStringObj.value}
                        }
                        //Sinon on indique qu'il n'y a pas d'interval de paiement
                        else{
                            prods[indice] = {"intervalP1": "", "intervalP2": ""}
                        }
                    }   
                )
                this.produits = prods

                if(this.$route.fullPath=="/panier/commande" || this.$store.state.currentUser.mail){
                    var mesDonnees = new FormData();
                    mesDonnees.set("mail", this.$store.state.currentUser.mail);
                    axios({
                        method: 'post',
                        url: json_config.phpFiles_url + '/getSites.php',
                        data: mesDonnees
                    })
                    .then((result) => result.data)
                    .then((result) => {
                        this.abonnements = result;
                        console.log(result)
                        this.commander(true)
                    })  
                }
            })
        }

        //Si le panier a changé alors j'envoie le nouveau panier a cocart sans creer une nouvelle intention de paiement
        /*
        if(JSON.stringify(this.$store.state.vieuxPanier)!=JSON.stringify(this.$store.state.panier)){
            var obj = { "cocart": "nouveauPanier", "intentType": "none", "amount": this.$store.getters.getPrixPanierCents, "router": this.$router, "updatVieuxPanier":true}
            this.$store.dispatch("sendToCocart", obj )
        }*/
    }
}
</script>


<style scoped>

.backToDesk{
    font-size: 34px;
}

.interval_abo{
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #cccbcb;
}

.eur_sign{
    font-size: 14px;
    line-height: 0.6;
    padding-top: 2px;
}

.middle_3_3_2_general{
    display: flex;
    align-items: flex-start;
}

.couponListe{
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.retirerCoupon{
    color: #cccbcb;
    cursor: pointer;
    transition: all .3s;
}

.retirerCoupon:hover{
    color: #f2505d;
}

.gotCoupon{
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #cccbcb;
    cursor: pointer;
    margin-bottom: 10px;
    width: fit-content;
}

.gotCoupon:hover{
    color: #04D98B;
}

.couponMessage{
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    min-height: 30px;
    display: flex;
    align-items: center;
    max-width:fit-content;
}

.invisible{
    visibility: hidden;
}

.couponOk{
    color: #04D98B;
}

.couponError{
    color: #f2505d;
}

.oldPrice{
    font-weight: 400;
    font-size: 14px;
    text-decoration: line-through;
    color: #cccbcb;
}

.ensemble_coupon{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.boutton_coupon{
    display: flex;
    align-items: center;
    justify-content: center;
    /*min-width: 140px;*/
    /*height: 40px;*/
    width: fit-content;
    /*background: #022873;*/
    cursor: pointer;
    /*border-radius: 10px;*/
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 27px;
    color: black;
    transition: all .3s;
}

.boutton_coupon:hover{
    color: #04D98B;
}

input:focus-visible, select:focus-visible{
    outline: #42b983 auto 1px;
}

input, input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus{
    font-family: "Lato";
    font-style: normal;
    font-weight: 400px;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    padding-top: 6px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 6px;
    background-color: transparent;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.boutton_offre_container{
    display: flex;
    margin-bottom: 64px;
}

.boutton_offre{
    position: inherit;
}

a{
    text-decoration: none;
}

.produit{
    margin-bottom: 36px;
}

.flex{
    display: flex;
}

.space_between{
    justify-content: space-between;
}

.tableDisplay{
    display: table;
}

.table_cell{
    display: table-cell;
}

.table_middle{
    vertical-align: middle;
}

.main{
    display: flex;
    justify-content: space-evenly;
    padding-top: 178px;
    width: -webkit-fill-available;
    min-height: -webkit-fill-available;
}

.middle{
    text-align: left;
    min-width: 310px;
    margin-right: 20px;
    margin-left: 20px;
}

.middle_1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.middle_2{
    height: 1px;
    background-color:#04D98B;
    width: 150px;
    margin-top: 4px;
    margin-bottom: 40px;
}

.middle_3_1{
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}

.middle_3_1_1{
    margin-right: 11px;
}

.middle_3_2{
    margin-top: 6px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #CCCBCB;
}

.middle_3_3{
    margin-top: 16px;
    display: table;
    width: 167px;
    height: 38px;
    background: #F2F3FB;
    border-radius: 10px;
}

.middle_3_3_1{
    padding-left: 16px;
}

.middle_3_3_2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.middle_3_4{
    margin-top: 10px;
    width: fit-content;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #CCCBCB;
}

.middle_3_5{
    border: 1px solid #CCCBCB;
    margin-bottom: 16px;
}

.middle_3_6{
    margin-bottom:35px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.middle_3_6_2_2{
    text-align: right;
    padding-left: 20px;
}

.right{
    width: 381px;
    height: fit-content;
    background: #F2F3FB;
    border-radius: 20px;
}

.right_1{
    margin-left: 56px;
    margin-top: 56px;
    margin-right: 56px;
    margin-bottom: 8px;
}

.right_1_1{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

@media screen and (max-width: 800px){
    .main{
        flex-direction: column;
    }

    .right{
        margin-bottom: 50px;
    }

    .left{
        margin-bottom: 20px;
        text-align: left;
        margin-left: 20px;
    }

    .middle{
        width: fit-content;
        margin-right: auto;
        margin-left: auto;
    }

    .right{
        margin-right: auto;
        margin-left: auto;
    }
}

@media screen and (max-width: 400px){
    .right{
        width: 100%;
        border-radius: 0px;
    }
}

@media screen and (max-width: 350px){
    .middle{
        width:250px;
        min-width: auto;
    }
}
</style>